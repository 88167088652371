import { FC } from 'react';
import styles from './Badge.module.scss';

interface BadgeProps {
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string,
}

const Badge: FC<BadgeProps> = ({ backgroundColor = 'var(--gray-200)', color = 'var(--gray-800)', onClick, children, style, className }) => {
  return (
    <span 
      className={`${styles.badge} ${className}`} 
      style={{ backgroundColor, color, ...style }} 
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default Badge;
