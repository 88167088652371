import axios from 'axios';
import { Purchase } from '../ts-components/page-projects/MassMessagingModal/MassMessagingModal';

type Recipient = {
  projectId: string,
  areaId: string,
  supplierId: string,
  supplierMemberId: string,
}

export const getSupplierToMassMessageTo = async (projectId: string): Promise<Purchase[]> => {
  const response = await axios.get(`/api/massMessage/${projectId}/`);
  return response.data;
}

export const sendMassMessage = async (recipients: Recipient[], message: string) => {
  const response = await axios.post(`/api/massMessage/send`, {
    recipients,
    message,
  });
  return response.data;
}
