import BaseService from './baseService';
import axios from 'axios';

export class ServiceOrganization extends BaseService {
  getOrganizations = async (params) => {
    const response = await axios.get('/api/organisations', {params});
    return response.data;
  };

  saveOrganization = async (data) => {
    const {logo, salesOrgContract, ...rest} = data;
    const formData = new FormData();
    formData.append("json", JSON.stringify(rest));

    if (logo) {
      formData.append('logo', logo);
    }
    if (salesOrgContract) {
      formData.append('salesOrgContract', salesOrgContract);
    }
    const response = await axios.post('/api/organisation', formData);
    return response.data;
  };

  updateOrganization = async (data) => {
    const formData = new FormData();
    formData.append("json", JSON.stringify(data));

    if (data?.logo) {
      formData.append('logo', data?.logo);
    }
    if (data?.salesOrgContract) {
      formData.append('salesOrgContract', data?.salesOrgContract);
    }
    const response = await axios.put('/api/organisation', formData);
    return response.data;
  };

  saveSettings = async (data) => {
    const response = await axios.put('/api/organisation/settings', data);    
    return response.data;
  }

  getOrganizationsCount = async ()=>{
   try {
    const response = await axios.get('/api/organisation/totalCount');
  
    return response.data;
   } catch (error) {
     return null;
   }
  }

  validateAndGetOrgSlug = async (domainName, orgSlug) => {
    const response = await axios.get(`/api/validateAndGetOrgSlug/domain/${domainName}?orgSlug=${orgSlug}`);
    return response.data;
  }

  updateField = async (field, value) => {
    const response = await axios.put(`/api/organisation/fields/${field}`, {data: value});
    return response.data;
  }

  getField = async (field) => {
    const response = await axios.get(`/api/organisation/fields/${field}`);
    return response.data;
  }
}

export let serviceOrganization = new ServiceOrganization();
