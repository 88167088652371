// @ts-nocheck
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Modal from "../../../components/shared/Modal/Modal";
import styles from "./MassMessagingModal.module.scss";
import StyledCheckbox from "../../../components/shared/StyledCheckbox/StyledCheckbox";
import { getSupplierToMassMessageTo, sendMassMessage } from '../../../services/massMessageService';
import { ReactComponent as LightArrow } from '../../../assets/svg/lightArrow.svg';
import Badge from "../../../components/shared/Badge/Badge";
import TooltipContainer from "../../../components/shared/TooltipContainer/TooltipContainer";
import Editor from "../../../components/shared/Editor/Editor";
import appState from "../../../state/AppStateContainer";
import { Loading } from "../../../components/shared";
import { NotificationManager } from "react-notifications";
import * as Sentry from "@sentry/react";
import { ErrorMessageIcon } from '../../../components/shared/ErrorMessageIcon/ErrorMessageIcon';
import { format } from "date-fns";
import { serviceOrganization, } from "../../../services/service-organization";
import { ReactComponent as InfoIcon } from '../../../assets/svg/InfoIconLight.svg'

type Supplier = {
  supplierId: string,
  supplierName: string,
  won: boolean,
  intent: 'DECLINED' | 'ACCEPTED',
  hasBid: boolean,
  contacts: Contact[],
}

export type Purchase = {
  areaId: string,
  areaName: string,
  suppliers: Supplier[],
}

type Contact = {
  id: string,
  name: string,
  email: string,
  title: string,
}

type Props = {
  projectId: string,
}

type AreaSelectionType = boolean | { [x: string]: SupplierSelectionType};
type SupplierSelectionType = boolean | { [x: string]: ContactSelectionType};
type ContactSelectionType = boolean;

export const MassMessagingModal: FC<Props> = ({ projectId, }) => {
  const [message, setMessage] = useState('');
  const [purchases, setPurchases] = useState<Purchase[]>([]);
  const [recipientSelection, setRecipientSelection] = useState<{[x: string]: AreaSelectionType}>({});
  const [shownRecipients, setShownRecipients] = useState<string[]>([]);
  const showMassMessagingModal = appState.getShowMassMessagingModal();
  const [sendingMessage, setSendingMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [massMessagingOrgInfo, setMassMessagingOrgInfo] = useState({});

  const SINGLE_LIMIT = massMessagingOrgInfo?.singleMessageLimit || 200;
  const WEEKLY_LIMIT = massMessagingOrgInfo?.weeklyMessagesLimit || 600;
  const weeklyInfo = massMessagingOrgInfo?.messagesSentWeekly;
  const infoThisWeek = weeklyInfo?.[format(new Date(), 'YYYY-ww', {useAdditionalWeekYearTokens: true,})] || {amount: 0, limit: WEEKLY_LIMIT};

  const resetModal = useCallback(() => {
    setMessage('');
    setPurchases([]);
    setRecipientSelection({});
    setShownRecipients([]);
  }, []);

  const setShowMassMessagingModal = useCallback((value: boolean) => {
    appState.setShowMassMessagingModal(value);
  }, []);

  useEffect(() => {
    if (showMassMessagingModal) {
      resetModal();
      (async () => {
        setLoading(true);
        const purchases = await getSupplierToMassMessageTo(projectId);
        setPurchases(purchases);
        const massMessagingOrgInfo = await serviceOrganization.getField('massMessaging');
        setMassMessagingOrgInfo(massMessagingOrgInfo);
        setLoading(false);
      })();
    }
  }, [showMassMessagingModal]);

  const getSupplierBadge = useCallback((supplier: Supplier) => {
    const badgeProps: {backgroundColor?: string, color?: string} = {};
    let badgeText = '';
    if (supplier.won) {
      badgeText = 'Vinnande leverantörer';
      badgeProps.backgroundColor = 'var(--yellow-100)';
      badgeProps.color = 'var(--yellow-800)';
    } else if (supplier.intent === 'DECLINED') {
      badgeText = 'Avser inte lämna anbud';
      badgeProps.backgroundColor = 'var(--red-100)';
      badgeProps.color = 'var(--red-800)';
    } else if (supplier.intent === 'ACCEPTED') {
      badgeText = 'Avser lämna anbud';
      badgeProps.backgroundColor = 'var(--green-100)';
      badgeProps.color = 'var(--green-800)';
    }

    if (badgeText) {
      return <Badge className={styles.supplierBadge} {...badgeProps}>{badgeText}</Badge>;
    }
  }, []);

  const checkSupplier = useCallback((checked: boolean, purchase: Purchase, supplier: Supplier) => {
    setRecipientSelection(prevMassMessagingRecipientSelection => {
      const newMassMessagingRecipientSelection = {...prevMassMessagingRecipientSelection};
      let newAreaSelection: AreaSelectionType = {...(newMassMessagingRecipientSelection[purchase.areaId] as Record<string, boolean>) , [supplier.supplierId]: checked};
      if (checked) {
        if (Object.entries(newAreaSelection).filter(([_, value]) => value === true).length === purchase.suppliers.length) {
          newAreaSelection = true;
        }
      } else if (!checked) {
        if (newMassMessagingRecipientSelection[purchase.areaId] === true) {
          newAreaSelection = { ...purchase.suppliers.reduce((acc: Record<string, boolean>, current) => {
            acc[current.supplierId] = true;
            return acc;
          }, {}), [supplier.supplierId]: false };

          if (Object.values(newAreaSelection).filter(value => value).length === 0) {
            newAreaSelection = false;
          }
        } else if (newMassMessagingRecipientSelection[purchase.areaId] !== true && Object.entries(newAreaSelection).filter(([_, value]) => value).length === 0) {
          newAreaSelection = false;
        }
      }

      return { ...newMassMessagingRecipientSelection, [purchase.areaId]: newAreaSelection }
    });
  }, []);

  const checkPurchase = useCallback((checked: boolean, purchase: Purchase) => {
    setRecipientSelection(prevState => ({ ...prevState, [purchase.areaId]: checked }));
  }, []);

  const checkContact = useCallback((checked: boolean, purchase: Purchase, supplier: Supplier, contact: Contact) => {
    setRecipientSelection(prevMassMessagingRecipientSelection => {
      const newMassMessagingRecipientSelection = {...prevMassMessagingRecipientSelection};

      const currentSupplierSelection = newMassMessagingRecipientSelection[purchase.areaId]?.[supplier.supplierId] as (Record<string, boolean> | boolean);
      let newSupplierSelection = {...currentSupplierSelection, [contact.id]: checked};
      if (checked) {
        if (Object.entries(newSupplierSelection).filter(([_, value]) => value).length === supplier.contacts.length) {
          newSupplierSelection = true;
        }
      } else if (!checked) {
        if (currentSupplierSelection === true || newMassMessagingRecipientSelection[purchase.areaId] === true) {
          if (supplier.contacts.length > 1) {
            newSupplierSelection = { ...supplier.contacts.reduce((acc: Record<string, boolean>, current) => {acc[current.id] = true; return acc;}, {}), [contact.id]: false };
          } else {
            newSupplierSelection = false;
          }
        } else if (Object.entries(newSupplierSelection).filter(([_, value]) => value).length === 0) {
          newSupplierSelection = false;
        }
      }

      let newAreaSelection: AreaSelectionType = {...newMassMessagingRecipientSelection[purchase.areaId], [supplier.supplierId]: newSupplierSelection};
      if (newMassMessagingRecipientSelection[purchase.areaId] === true && purchase.suppliers.length > 1) {
        newAreaSelection = { ...purchase.suppliers.reduce((acc: Record<string, boolean>, current) => {acc[current.supplierId] = true; return acc;}, {}), [supplier.supplierId]: newSupplierSelection };
      }
      
      if (newSupplierSelection === false && Object.entries(newAreaSelection).filter(([_, value]) => value).length === 0) {
        newAreaSelection = false;
      } else if (newSupplierSelection === true && Object.entries(newAreaSelection).every(([_, value]) => value) && Object.entries(newAreaSelection).length === purchase.suppliers.length) {
        newAreaSelection = true;
      }

      return { ...newMassMessagingRecipientSelection, [purchase.areaId]: newAreaSelection }
    })
  }, []);

  type RecipientListType = { contact: Contact; projectId: string; areaId: string; areaName: string; supplierId: string; }[];

  const getContacts = useCallback((areaId: string, suppliers: Supplier[]) => {
    const purchase = purchases.find(purchase => purchase.areaId === areaId);
    if (purchase) {
      return suppliers.reduce((acc: RecipientListType, current) => ([...acc, ...current.contacts.map(c => ({contact: c, projectId, areaId, areaName: purchase.areaName, supplierId: current.supplierId}))]),[]);
    }

    return [];
  }, [purchases, projectId]);

  const recipientList = useMemo(() => Object.entries(recipientSelection).reduce((acc: RecipientListType, [key, value]) => {
    if (value === true) {
      const purchase = purchases.find(purchase => purchase.areaId === key);
      if (purchase) {
        const suppliers = getContacts(purchase.areaId, purchase.suppliers);
        return [...acc, ...suppliers];
      }
      return acc;
    } else {
      const purchase = purchases.find(purchase => purchase.areaId === key);
      if (purchase) {
        const area = Object.entries(value);
        const suppliers = getContacts(purchase.areaId, area.filter(([_, sup]) => sup === true).map(([supId, _]) => purchase.suppliers.find(y => y.supplierId === supId)).filter(x => !!x));
        const areasWithIndividualSelections = area.filter(([_, sup]) => sup !== true);
        const subTotal = areasWithIndividualSelections.reduce(
          (acc: RecipientListType, [supId, sup]) =>
            ([...acc, ...Object.entries(sup).filter(
              ([_, con]) => con === true
            ).map(
              ([conId]) => {
                const supplier = purchase.suppliers.find(y => y.supplierId === supId);
                if (supplier) {
                  const contact = supplier?.contacts.find(contact => contact.id === conId);
                  if (contact) {
                    return {contact, projectId, areaId: purchase.areaId, areaName: purchase.areaName, supplierId: supId};
                  }
                }
                
              }).filter(x => !!x)]), []);

        return [...acc, ...suppliers, ...subTotal];
      }

      return acc;
    }
  }, []));
  

  const sendMessage = useCallback(async () => {
    setSendingMessage(true);
  
    try {
      const list = recipientList.map(x => ({
        projectId: x.projectId,
        areaId: x.areaId,
        supplierId: x.supplierId,
        supplierMemberId: x.contact.id
      }));

      await sendMassMessage(list, message);
      setShowMassMessagingModal(false);

      NotificationManager.success('Skickat meddelande', 'Skickat');
    } catch (error) {
      const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
      NotificationManager.error(errorMsg, 'Misslyckat');
      Sentry.captureException(error);
    } finally {
      setSendingMessage(false);
    }
  }, [recipientList, message]);

  const recipientsByFilter = useMemo(() => {
    const filteredRecipients: {[key: string]: {areaId: string, supplierId: string}[]} = {
      'winning': [],
      'not-winning': [],
      'intent-accepted': [],
      'intent-declined': [],
      'has-bid': [],
    }

    for (const purchase of purchases) {
      for (const supplier of purchase.suppliers) {
        if (supplier.won) {
          filteredRecipients['winning'].push({areaId: purchase.areaId, supplierId: supplier.supplierId});
        }
        if (!supplier.won) {
          filteredRecipients['not-winning'].push({areaId: purchase.areaId, supplierId: supplier.supplierId});
        }
        if (supplier.intent === 'ACCEPTED') {
          filteredRecipients['intent-accepted'].push({areaId: purchase.areaId, supplierId: supplier.supplierId});
        }
        if (supplier.intent === 'DECLINED') {
          filteredRecipients['intent-declined'].push({areaId: purchase.areaId, supplierId: supplier.supplierId});
        }
        if (supplier.hasBid) {
          filteredRecipients['has-bid'].push({areaId: purchase.areaId, supplierId: supplier.supplierId});
        }
      }
    }

    return filteredRecipients;
  }, [purchases]);

  const aboveLimitErrorMessage = useMemo(() => {
    if (recipientList.length + infoThisWeek.amount > WEEKLY_LIMIT) {
      return <div style={{ fontSize: '12px' }}>Med det nuvarande urvalet är du <b>{recipientList.length + infoThisWeek.amount - WEEKLY_LIMIT}</b> mottagare över din organisations veckogräns på <b>{WEEKLY_LIMIT}</b> mottagare per vecka.</div>;
    } else if (recipientList.length > SINGLE_LIMIT) {
      return <div style={{ fontSize: '12px' }}>Du kan max skicka meddelanden till <b>{SINGLE_LIMIT}</b> personer per meddelande.</div>;
    }
  }, [recipientList, infoThisWeek.amount]);
  
  return (
    <Modal
      title='Massutskick'
      show={showMassMessagingModal}
      setShow={(value) => setShowMassMessagingModal(value)}
      hasCloseButton
      buttonInfo={{
        label: 'Skicka',
        action: () => sendMessage(),
        disabled: !message || recipientList.length === 0 || !!aboveLimitErrorMessage,
        loaderShow: sendingMessage,
      }}
    >
      <div className={styles.massMessagingModal}>
        <div className={styles.quickSelectContainer}>
          <span>Snabbval</span>
          <div className={styles.filterContainer}>
            <label className={`${recipientsByFilter['winning'].length === 0 ? styles.disabledQuickSelect : ''}`}>
              <StyledCheckbox
                onChange={async (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  for (const purchase of purchases) {
                    const suppliersToCheck = purchase.suppliers.filter(supplier => supplier.won);

                    for (const supplier of suppliersToCheck) {
                      checkSupplier(checked, purchase, supplier);
                    }
                  }
                }}
                checked={recipientsByFilter['winning'].some(x => recipientList.find(y => y.areaId === x.areaId && y.supplierId === x.supplierId))}
                dash={recipientsByFilter['winning'].length !== recipientList.length && !recipientsByFilter['winning'].every(x => recipientList.find(y => y.areaId === x.areaId && y.supplierId === x.supplierId))}
              />
              <span>Vinnande leverantörer</span>
            </label>
            <label className={`${recipientsByFilter['not-winning'].length === 0 ? styles.disabledQuickSelect : ''}`}>
              <StyledCheckbox
                onChange={async (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  for (const purchase of purchases) {
                    const suppliersToCheck = purchase.suppliers.filter(supplier => !supplier.won);

                    for (const supplier of suppliersToCheck) {
                      checkSupplier(checked, purchase, supplier);
                    }
                  }
                }}
                checked={recipientsByFilter['not-winning'].some(x => recipientList.find(y => y.areaId === x.areaId && y.supplierId === x.supplierId))}
                dash={recipientsByFilter['not-winning'].length !== recipientList.length && !recipientsByFilter['not-winning'].every(x => recipientList.find(y => y.areaId === x.areaId && y.supplierId === x.supplierId))}
              />
              <span>Ej vinnande leverantörer</span>
            </label>
            <label className={`${recipientsByFilter['intent-accepted'].length === 0 ? styles.disabledQuickSelect : ''}`}>
              <StyledCheckbox
                onChange={async (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  for (const purchase of purchases) {
                    const suppliersToCheck = purchase.suppliers.filter(supplier => supplier.intent === 'ACCEPTED');

                    for (const supplier of suppliersToCheck) {
                      checkSupplier(checked, purchase, supplier);
                    }
                  }
                }}
                checked={recipientsByFilter['intent-accepted'].some(x => recipientList.find(y => y.areaId === x.areaId && y.supplierId === x.supplierId))}
                dash={recipientsByFilter['intent-accepted'].length !== recipientList.length && !recipientsByFilter['intent-accepted'].every(x => recipientList.find(y => y.areaId === x.areaId && y.supplierId === x.supplierId))}
              />
              <span>Avser lämna anbud</span>
            </label>
            <label className={`${recipientsByFilter['intent-declined'].length === 0 ? styles.disabledQuickSelect : ''}`}>
              <StyledCheckbox
                onChange={async (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  for (const purchase of purchases) {
                    const suppliersToCheck = purchase.suppliers.filter(supplier => supplier.intent === 'DECLINED');

                    for (const supplier of suppliersToCheck) {
                      checkSupplier(checked, purchase, supplier);
                    }
                  }
                }}
                checked={recipientsByFilter['intent-declined'].some(x => recipientList.find(y => y.areaId === x.areaId && y.supplierId === x.supplierId))}
                dash={recipientsByFilter['intent-declined'].length !== recipientList.length && !recipientsByFilter['intent-declined'].every(x => recipientList.find(y => y.areaId === x.areaId && y.supplierId === x.supplierId))}
              />
              <span>Avser inte lämna anbud</span>
            </label>
            <label className={`${recipientsByFilter['has-bid'].length === 0 ? styles.disabledQuickSelect : ''}`}>
              <StyledCheckbox
                onChange={async (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  for (const purchase of purchases) {
                    const suppliersToCheck = purchase.suppliers.filter(supplier => supplier.hasBid);

                    for (const supplier of suppliersToCheck) {
                      checkSupplier(checked, purchase, supplier);
                    }
                  }
                }}
                checked={recipientsByFilter['has-bid'].some(x => recipientList.find(y => y.areaId === x.areaId && y.supplierId === x.supplierId))}
                dash={recipientsByFilter['has-bid'].length !== recipientList.length && !recipientsByFilter['has-bid'].every(x => recipientList.find(y => y.areaId === x.areaId && y.supplierId === x.supplierId))}
              />
              <span>Lämnat anbud</span>
            </label>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center', height: '20px' }}>
            <TooltipContainer renderReferenceComponent={(className, ref) => <span ref={ref} className={className} style={{ width: 'fit-content' }}>Välj mottagare ({recipientList.length})</span>}>
              {recipientList.length > 0 && (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', maxWidth: '400px' }}>
                  {recipientList.map(recipient => (
                    <Badge
                      key={`${recipient.areaId}${recipient.supplierId}${recipient.contact.id}`}
                      backgroundColor='var(--gray-200)' color='var(--gray-800)' style={{ width: 'fit-content' }}
                    >
                      {recipient.contact.name}
                    </Badge>
                  ))}
                  
                </div>
              )}
            </TooltipContainer>
            {!aboveLimitErrorMessage && <TooltipContainer renderReferenceComponent={(className, ref) => <InfoIcon ref={ref} className={className} style={{ width: 'fit-content' }} />}>
              <div style={{ fontSize: '12px', maxWidth: '400px' }}>Det finns en gräns på <b>{SINGLE_LIMIT}</b> mottagare per meddelande och <b>{WEEKLY_LIMIT}</b> mottagare varje vecka för organisationen. Du kan maximalt skicka till <b>{WEEKLY_LIMIT - infoThisWeek.amount}</b> ytterligare mottagare denna vecka.</div>
            </TooltipContainer>}
            {aboveLimitErrorMessage && (
              <ErrorMessageIcon errorMessage={aboveLimitErrorMessage} />
            )}
          </div>

          {purchases && purchases.length > 0 ? (
            <div className={styles.treeView}>
              <label style={{ width: 'fit-content' }}><StyledCheckbox checked={Object.entries(recipientSelection).filter(([_, value]) => value).length > 0} dash={Object.entries(recipientSelection).filter(([_, value]) => value === true).length !== purchases.length}
                onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  setRecipientSelection(
                    checked ? purchases.reduce((acc: Record<string, boolean>, current) => {
                      acc[current.areaId] = true;
                      return acc;
                    }, {}) :
                    {}
                  );
                }}
              />
                <span>Alla inköp</span>
              </label>
              {purchases.filter(purchase => !!purchase).map(purchase => {
                return (
                  <div key={purchase.areaId} className={`${styles.smallIndentation} ${shownRecipients.includes(purchase.areaId) ? styles.show : ''}`}>
                    <div className={styles.purchaseName}>
                      <LightArrow
                        onClick={() => {
                          setShownRecipients(shownRecipients.includes(purchase.areaId) ? shownRecipients.filter(y => y !== purchase.areaId || !y.startsWith(purchase.areaId)) : [...shownRecipients, purchase.areaId]);
                        }}
                        className={`${styles.indentationArrow} ${styles.areaArrow}`}
                      />
                      <label>
                        <StyledCheckbox
                          checked={!!recipientSelection[purchase.areaId]}
                          dash={recipientSelection[purchase.areaId] !== true}
                          onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => checkPurchase(checked, purchase)}
                        />
                        <span>{purchase.areaName}</span>
                      </label>
                    </div>
                    {purchase.suppliers.map(supplier => {
                      return (
                        <div key={`${purchase.areaId}${supplier.supplierId}`} className={`${styles.indent} ${shownRecipients.includes(`${purchase.areaId}${supplier.supplierId}`) ? styles.show : ''}`}>
                          <div style={{ display: 'flex', alignItems: 'center',}}>
                            <LightArrow className={`${styles.indentationArrow} ${styles.supplierArrow}`} onClick={() => setShownRecipients(shownRecipients.includes(`${purchase.areaId}${supplier.supplierId}`) ? shownRecipients.filter(y => y !== `${purchase.areaId}${supplier.supplierId}`) : [...shownRecipients, `${purchase.areaId}${supplier.supplierId}`])} />
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                              <label>
                                <StyledCheckbox checked={!!recipientSelection[purchase.areaId]?.[supplier.supplierId] || recipientSelection[purchase.areaId] === true} dash={recipientSelection[purchase.areaId]?.[supplier.supplierId] !== true && recipientSelection[purchase.areaId] !== true} onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => checkSupplier(checked, purchase, supplier)}/>
                                <span>{supplier.supplierName}</span>
                              </label>
                              {getSupplierBadge(supplier)}
                            </div>
                          </div>
                          {supplier.contacts.map(contact => {
                            return (
                              <div key={`${purchase.areaId}${contact.id}`} className={styles.bigIndentation}>
                                <label><StyledCheckbox checked={!!recipientSelection[purchase.areaId]?.[supplier.supplierId]?.[contact.id] || recipientSelection[purchase.areaId]?.[supplier.supplierId] === true || recipientSelection[purchase.areaId] === true}
                                onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => checkContact(checked, purchase, supplier, contact)}
                                />
                                
                                <span>{`${contact.name}${contact.title ? `, ${contact.title}` : ''} <${contact.email}>`}</span></label>
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          ) : (
            <span style={{ alignSelf: 'center', marginBottom: '16px' }}>
              {loading ? <Loading type='inline' /> : 'Du har inga mottagare'}
            </span>
          )}
        </div>
        <Editor
          value={message}
          onChange={(value) => setMessage(value)}
          wrapperStyle={{ height: '291px' }}
        />
      </div>
    </Modal>
  )
}