import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import ReactLoading from 'react-loading';

import { ServiceSupplier } from '../../services/service-supplier';
import { CalendarIcon, CrossSvg } from '../../components/svgs';
import MultiSelect from '../../components/shared/MultiSelect';
import AppLabel from '../app-label/app-label';

import { areEqual, validateEmail } from '../../util';
import { checkOrgNo, isValidOrganizationNumber } from '../../utils/bt-number';
import 'react-toggle/style.css';
import { CustomToggle, Button } from '../../components/shared';
import ReactDatePicker from 'react-datepicker';
import services from '../../services';
import { serviceOrganization } from '../../services/service-organization';
import { credibilityData } from '../../staticData';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import BtButton from '../bt-button/bt-button';
import './styles.css';
import ImagePreview from '../../components/shared/ImagePreview/ImagePreview';
import { FileScope } from '../../util/appEnum';
import Modal from '../../components/shared/Modal/Modal';
import TitleWrapper from '../../components/shared/TitleWrapper/TitleWrapper';
import StyledCheckbox from '../../components/shared/StyledCheckbox/StyledCheckbox';
import File from '../../components/File/File';
import { ReactComponent as InfoIcon } from '../../assets/svg/InfoIconLight.svg'
import TooltipContainer from '../../components/shared/TooltipContainer/TooltipContainer';
import appState from '../../state/AppStateContainer';
import { format } from 'date-fns';

const emailProviders = [
  {value: "DEFAULT", label: "Default (Sendgrid)"},
  {value: "SENDGRID", label: "Sendgrid (US)"},
  {value: "SENDINBLUE", label: "Sendinblue (EU)"},                
];

const AddOrganization = ({ onModalHide, intl, saveOrg, editOrgData, show }) => {
  const [btnLoader, setBtnLoader] = useState(false);
  const [isActive, toggleIsActive] = useState(true);
  const [preApproveRecommendedSuppliers, setPreApproveRecommendedSuppliers] = useState(false);
  const [expiry, setExpiry] = useState(new Date());
  const [data, setData] = useState({ kommun: '', organizationNumber: '', name: '', email: '', credibilityUser: '', credibilityNumber: '', isActive: isActive, preApproveRecommendedSuppliers, microsoftTenantId: '', logo: {}, emailProvider: emailProviders[0], activeProducts: ["PURCHASING"], emailSuffixes: "", invoiceEmail: '', invoiceRef: '', salesOrgContract: null });
  const [cities, setCities] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      const citiesData = await services.getCities();
      setCities(citiesData);
    };

    if (editOrgData) setOrgData();

    fetchCities();
  }, []);

  const setOrgData = () => {
    let _orgData = {
      kommun: _.isObject(editOrgData.kommun)
        ? editOrgData.kommun
        : { label: editOrgData.kommun, value: editOrgData.kommun },
      credibility: _.isObject(editOrgData.credibility)
        ? { label: editOrgData.credibility.serviceName, value: editOrgData.credibility.serviceName } : null,
      organizationNumber: editOrgData.organizationNumber,
      name: editOrgData.name,
      slug: editOrgData.slug,
      email: editOrgData.email,
      credibilityUser: _.isObject(editOrgData.credibility) ? editOrgData.credibility.user : "",
      credibilityNumber: _.isObject(editOrgData.credibility) ? editOrgData.credibility.number : "",
      microsoftTenantId: editOrgData.microsoftTenantId ?? '',
      customerType: editOrgData.customerType || '',
      invoiceEmail: editOrgData.invoiceEmail || '',
      invoiceRef: editOrgData.invoiceRef || '',
      customerType: editOrgData.customerType || '',
      logo: editOrgData.logo || {},
      salesOrgContract: editOrgData.salesOrgContract || null,
      emailProvider: editOrgData.emailProvider ? emailProviders.find(x=>x.value == editOrgData.emailProvider) : emailProviders[0],
      activeProducts: editOrgData.activeProducts ?? [],
      emailSuffixes: editOrgData.emailSuffixes ?? "",
      massMessaging: {...editOrgData.massMessaging, singleMessageLimit: editOrgData.massMessaging?.singleMessageLimit ? editOrgData.massMessaging?.singleMessageLimit+'' : '', weeklyMessagesLimit: editOrgData.massMessaging?.weeklyMessagesLimit ? editOrgData.massMessaging?.weeklyMessagesLimit+'' : ''} ?? {},
    };
    setData(_orgData);
    toggleIsActive(editOrgData.isActive);
    setPreApproveRecommendedSuppliers(editOrgData.preApproveRecommendedSuppliers);
    if (editOrgData && editOrgData.expiry) setExpiry(new Date(editOrgData.expiry));
  };

  const onSaveOrganisation = async () => {
    setBtnLoader(!btnLoader);

    let formErrors = [];
    let formData = {
      name: '',
      kommun: '',
      organizationNumber: '',
      email: '',
      expiry: '',
      microsoftTenantId: '',
      logo: {},
      salesOrgContract: null,
      invoiceEmail: '',
      invoiceRef: '',
      activeProducts: [],
      emailSuffixes: '',
      massMessaging: {},
    };
    if(data.credibility) {
      data.credibilityData = {
        serviceName: data.credibility.value,
        user: data.credibilityUser, 
        number: data.credibilityNumber
      }
    }

    formData = Object.assign(formData, data);
    formData.expiry = expiry;
    if (data.emailProvider) {
      formData.emailProvider = data.emailProvider.value;
    }
    const fieldNames = {
      name: 'Företagsnamn',
      kommun: 'Kommun',
      organizationNumber: 'Organisations nr',
      email: 'Email',
      invoiceEmail: 'E-postadress',
      invoiceRef: 'Referens',
      salesOrgContract: 'Avtal',
    };
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const optionalFields = ['isActive', 'preApproveRecommendedSuppliers', 'activeProducts', 'expiry', 'customerType', 'credibilityUser', 'credibilityNumber', 'credibility', 'microsoftTenantId', 'logo', 'emailProvider', 'emailSuffixes', 'massMessaging'];
        if (!data.activeProducts?.includes("SALES")) {
          optionalFields.push('salesOrgContract');
          optionalFields.push('invoiceEmail');
          optionalFields.push('invoiceRef');
        }
        if (optionalFields.includes(key)) continue;

        const val = formData[key];
        if (
          !val ||
          (val instanceof Array && val.length == 0) ||
          (key == 'organizationNumber' && val.length == 0) ||
          (key == 'email' && validateEmail(val))
        ) {
          formErrors.push(`${fieldNames[key]}`);
        }
      }
    }
    if (formErrors.length > 0) {
      setFormErrors(formErrors);
      setBtnLoader(false);
      return;
    }

    try {
      if (editOrgData) {
        formData.organizationId = editOrgData._id;
        let updatedData = await serviceOrganization.updateOrganization({ ...formData });
        handleSuccessResponse(updatedData, 'Leverantören har uppdaterats');
      } else {
        const org = await serviceOrganization.saveOrganization({ ...formData });
        formData._id = org._id;
        handleSuccessResponse(formData, 'Leverantören har lagts till');
      }
    } catch (e) {
      console.log(_.get(e, 'response.data.error'), '::::ERROR::::');
      handleErrorResponse(e);
    }
    
  };

  const handleSuccessResponse = (data, msg) => {
    NotificationManager.success(msg);
    setBtnLoader(false);
    saveOrg();
  };

  const handleErrorResponse = e => {
    setBtnLoader(false);
    NotificationManager.error(
      _.get(e, 'response.data.error', 'An unexpected error has occurred'),
      intl.formatMessage({
        id: 'global.error',
        defaultMessage: 'Error',
      }),
    );
  };

  const getShortName = name => {
    if (name.includes('Elie')) {
      return 'Elie';
    } else if (name.includes('Jakob')) {
      return 'Jakob';
    } else if (name.includes('Nabaz')) {
      return 'Nabaz';
    } else if (name.includes('George')) {
      return 'George';
    }
  }

  const handleChange = e => {
    let _data = data;
    let name = e.target.name;
    let value = e.target.value;
    if (['massMessaging.singleMessageLimit', 'massMessaging.weeklyMessagesLimit'].includes(name)) {
      value = value.replaceAll(/\D/g, '');

      const numberValue = +value;
      if (numberValue > 9999) {
        const userName = getShortName(appState.getUserName());
        if (userName !== 'George') {
          NotificationManager.error(`WTF ${userName} 🫢... Are you trying to get us flagged? 🤔`);
        } else {
          NotificationManager.success(`Go ahead boss 😎`);
        }
      }

      if (!_data.massMessaging) {
        _data.massMessaging = {
          [name.replace('massMessaging.', '')]: value,
        };
      } else {
        _data.massMessaging[name.replace('massMessaging.', '')] = value;
      }
    } else {
      _data[e.target.name] = value;
    }
    setData(prevState => ({ ...prevState, ..._data }));
  };

  const requiredValidator = key => {
    // if (
    //   formErrors.includes(key) &&
    //   key == 'Organisations nr' &&
    //   data.organizationNumber &&
    //   isValidOrganizationNumber(data.organizationNumber)
    // ) {
    //   return <div className="error show">OGILTIGT NUMMER</div>;
    // } else {
      return formErrors.includes(key) ? <div className="error show">OBLIGATORISKT</div> : null;
    // }
  };


  const toggleStatus = () => {
    toggleIsActive(!isActive);
    handleChange({ target: { name: 'isActive', value: !isActive } });
  };
  const togglePreApproval = () => {
    const newValue = !preApproveRecommendedSuppliers;
    setPreApproveRecommendedSuppliers(newValue);
    handleChange({ target: { name: 'preApproveRecommendedSuppliers', value: newValue } });
  };

  const onChangeDropdown = (val, event) => {
    let _data = data || {};
    _data[event.name] = val;
    setData(prevState => ({ ...prevState, ..._data }));
  }

  const renderFormSlide = () => {
    const weeklyMessagesLog = Object.entries(data?.massMessaging?.messagesSentWeekly || {});
    const thisWeekInfo = (data?.massMessaging?.messagesSentWeekly || {})[format(new Date(), 'YYYY-ww', {useAdditionalWeekYearTokens: true,})];

    return (
      <div className="mx-content overflow-s custom-scrollbar" style={{fontSize: "14px"}}>
        <div className={'slide-1'} key="slide1">
          <AppLabel class="ar-label" headline="Företagsnamn" required>
            <input
              className="ar-text-field"
              type="text"
              name="name"
              value={data.name}
              onChange={handleChange}
            />
            {requiredValidator('Företagsnamn')}
          </AppLabel>

          <AppLabel class="ar-label" headline="Organisationsnummer" required>
            <input
              className="ar-text-field"
              name="organizationNumber"
              value={data.organizationNumber}
              onChange={handleChange}
            />
            {requiredValidator('Organisations nr')}
          </AppLabel>
          {/* app-chat-mail-batch */}
          <AppLabel headline="Email" required class=" ar-label">
            <input className={`ar-text-field ${!!editOrgData ? 'disabled':''}`} name="email" value={data.email} onChange={handleChange} disabled={!!editOrgData} />
            {requiredValidator('Email')}
          </AppLabel>

          <AppLabel
            class="ar-label ar-select-label custom-ar-kommun-select"
            headline="Län"
            required
          >
            <Select
              placeholder="Välj"
              options={cities.map(c => {
                return {
                  value: c,
                  label: c,
                };
              })}
              className="c-autosuggest ar-select-field"
              value={data.kommun}
              name='kommun'
              onChange={onChangeDropdown}
              classNamePrefix="custom-dropdown-add-supp"
            />

            {requiredValidator('Kommun')}
          </AppLabel>

          <AppLabel class="ar-label" headline="Kundtyp">
            <input
              className="ar-text-field"
              name="customerType"
              value={data.customerType}
              onChange={handleChange}
            />
          </AppLabel>

          <CustomToggle checked={isActive} onChange={toggleStatus} icons={false} label="Aktiv" />

          <div style={{margin: "16px 0"}}>
            <span style={{color: "var(--gray-700)", fontWeight: 500}}>Produkter</span>
            <label style={{color: "var(--gray-700)", display: "flex", alignItems: "center"}}><StyledCheckbox checked={data.activeProducts.includes("PURCHASING")} onChange={
              event => {
                const product = "PURCHASING";
                setData(prevState => ({...prevState, activeProducts: [...prevState.activeProducts.filter(p => p != product), ...(event.target.checked ? [product] : [])]}))
              }
            } /><span>Inköp</span></label>
            <label style={{color: "var(--gray-700)", display: "flex", alignItems: "center"}}><StyledCheckbox checked={data.activeProducts.includes("SALES")} onChange={
              event => {
                const product = "SALES";
                setData(prevState => ({...prevState, activeProducts: [...prevState.activeProducts.filter(p => p != product), ...(event.target.checked ? [product] : [])]}))
              }
            } /> Försäljning</label>
          </div>

          {data.activeProducts.includes("SALES") && (
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px', borderBottom: '1px solid var(--gray-300)' }}>
              <label class="ar-label position-relative" headline="Avtal">Avtal</label>
              {data.salesOrgContract && (
                <div style={{ marginTop: '8px', marginBottom: '16px' }}>
                  <File
                    file={data.salesOrgContract?.file || data?.salesOrgContract?.name}
                    fileName={(data.salesOrgContract?.file ? data.salesOrgContract?.fileName : data.salesOrgContract?.name) ?? "Användaravtal.pdf"}                      
                    onRemove={() => {
                      handleChange({target: {name: 'salesOrgContract', value: undefined}});
                    }}
                    blob={data.salesOrgContract?.file ? undefined : data.salesOrgContract}
                    scope={FileScope.SalesOrgContract}
                    scopeOptions={{ organizationNumber: data.organizationNumber }}
                  />
                </div>
              )}
              {!data.salesOrgContract && (
                <label style={{ width: 'fit-content' }}>
                  <input
                    type='file'
                    accept='application/pdf'
                    name='logo'
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      setData(prevState => ({ ...prevState, salesOrgContract: e.target.files[0] }));
                    }}
                  />
                  <BtButton class='salesOrgContractButton' color='white' size='xxs' style={{ margin: 0, marginBottom: '16px', float: 'none', letterSpacing: 'normal' }}>
                    Ladda upp fil
                  </BtButton>
                </label>
              )}
              {requiredValidator('Avtal')}

              <AppLabel class="ar-label" headline="E-postadress">
                <input
                  className="ar-text-field"
                  name="invoiceEmail"
                  value={data.invoiceEmail}
                  onChange={handleChange}
                />
                {requiredValidator('E-postadress')}
              </AppLabel>

              <AppLabel class="ar-label" headline="Referens">
                <input
                  className="ar-text-field"
                  name="invoiceRef"
                  value={data.invoiceRef}
                  onChange={handleChange}
                />
                {requiredValidator('Referens')}
              </AppLabel>
            </div>
          )}

          <AppLabel class="ar-label" headline='Visa endast förfrågningar till e-postadresser som slutar på'>
            <p style={{textTransform: "none", display: "block", fontWeight: 400, color: "var(--gray-700)", maxWidth: "512px"}}>
              Endast för försäljningsorganisationer för att filterera bort test/demo-förfrågningar.               
              Separera olika adresser med komma t.ex. "@accurator.se, jakob@mailinator.com".
            </p>
            <input
              type="text"
              className="ar-text-field"
              name="emailSuffixes"
              value={data.emailSuffixes}
              autoComplete="off"
              onChange={handleChange}
              data-1p-ignore
            />
          </AppLabel>
     
          <AppLabel class="ar-label position-relative" headline="Avtalstid">
            <ReactDatePicker
              className="ar-text-field"
              value={expiry}
              selected={expiry}
              minDate={new Date()}
              popperPlacement="top-end"
              popperClassName="column-date-end-calendar-position-fix"
              onChange={date => setExpiry(date)}
              closeCalendar={true}
            />
            <CalendarIcon className="custom-style-calender"/>
          </AppLabel>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <span style={{ fontWeight: '500', color: 'var(--gray-700)' }}>Logotyp</span>
            <p style={{textTransform: "none", display: "block", fontWeight: 400, color: "var(--gray-700)", maxWidth: "512px"}}>Endast premiumkunder. 300 pixlar bred, transparent eller grå bakgrund.</p>
            <label style={{ width: 'fit-content' }}>
              <input
                type='file'
                accept='image/png, image/gif, image/jpeg'
                name='logo'
                style={{ display: 'none' }}
                onChange={(e) => {
                  setData(prevState => ({ ...prevState, logo: e.target.files[0] }));
                }}
              />
              {!(data?.logo?.fileName || data?.logo?.name) && (
                <BtButton color='white' size='xxs' style={{ margin: 0, float: 'none' }}>
                  <span style={{ margin: 0, letterSpacing: 'normal' }}>Ladda upp fil</span>
                </BtButton>
              )} 
            </label>
            {(data?.logo?.fileName || data?.logo?.name) && (
              <ImagePreview image={data?.logo} fileId={data?.logo?.file} scope={FileScope.OrganizationLogo} orgSlug={data?.slug} onRemove={() => setData(prevState => ({ ...prevState, logo: {}}))} />
            )}

            <TitleWrapper title='Visa endast förhandsgodkända rekommenderade leverantörer'>
              <CustomToggle checked={preApproveRecommendedSuppliers} onChange={togglePreApproval} icons={false}/>
            </TitleWrapper>

            <h3 className="inner-sec-title" style={{ width: '100%' }}>Massutskick</h3>
            <div style={{ display: 'flex', gap: '16px', }}>
              <AppLabel class="ar-label" headline="Max per utskick" style={{ flex: '1 1 33%' }}>
                <input
                  className="ar-text-field massMessagingLimitField"
                  name='massMessaging.singleMessageLimit'
                  value={data.massMessaging?.singleMessageLimit || ''}
                  autoComplete="off"
                  onChange={handleChange}
                  placeholder='200'
                />
              </AppLabel>
              <AppLabel class="ar-label" headline="Max per vecka" style={{ flex: '1 1 33%' }}>
                <input
                  className="ar-text-field massMessagingLimitField"
                  name='massMessaging.weeklyMessagesLimit'
                  value={data.massMessaging?.weeklyMessagesLimit || ''}
                  autoComplete="off"
                  onChange={handleChange}
                  placeholder='600'
                />
              </AppLabel>
              <AppLabel class="ar-label" headline={
                <div style={{ display: 'flex', gap: '8px' }}>
                  <div>
                    Skickade per vecka
                  </div>
                  <TooltipContainer
                    renderReferenceComponent={(className, ref) => weeklyMessagesLog.length > 0 && <InfoIcon className={className} ref={ref} />}
                  >
                    {weeklyMessagesLog.length > 0 && (
                      <ul style={{ margin: 0, paddingLeft: '16px' }}>
                        {weeklyMessagesLog.map(([week, {amount, limit}]) => (
                          <li>{week}: {amount}/{limit}</li>
                        ))}
                      </ul>
                    )}
                  </TooltipContainer>
                </div>
              }>
                <div style={{ marginTop: '16px', textTransform: 'none' }}>Denna vecka: {thisWeekInfo?.amount || 0}/{thisWeekInfo?.limit || data?.massMessaging?.weeklyMessagesLimit || 600}</div>
              </AppLabel>
            </div>
          </div>

          <h3 className="inner-sec-title">Tilläggstjänster</h3>

          <AppLabel class="ar-label" headline="Microsoft Tenant ID">
            <input
              className="ar-text-field"
              name="microsoftTenantId"
              value={data.microsoftTenantId}
              autoComplete="off"
              onChange={handleChange}
            />
          </AppLabel>

          <TitleWrapper title='Kreditupplysning'>
            <Select
              placeholder="Välj"
              options={credibilityData}
              name= "credibility"
              className='creditInfoSelector'
              value={data.credibility}
              onChange={onChangeDropdown}
              isClearable={true}
            />
          </TitleWrapper>
          <TitleWrapper title='E-posttjänst'>
            <Select
              placeholder="Välj"
              options={emailProviders}
              name= "emailProvider"
              className='creditInfoSelector'
              value={data.emailProvider ?? emailProviders[0]}
              onChange={onChangeDropdown}
            />
          </TitleWrapper>

          {data.credibility && renderCredibilityFields()}

        </div>
      </div>
    );
  };

  const renderCredibilityFields = () => {
    return (
      <Fragment>
        <AppLabel class="ar-label" headline="Användarnamn">
            <input
              className="ar-text-field"
              name="credibilityUser"
              value={data.credibilityUser}
              onChange={handleChange}
            />
        </AppLabel>
        <AppLabel class="ar-label" headline="Kundnummer">
            <input
              class="ar-text-field"
              name="credibilityNumber"
              value={data.credibilityNumber}
              onChange={handleChange}
            />
        </AppLabel>
      </Fragment>
    )
  }

  return (
    <Modal
      title={editOrgData ? 'Redigera organisation' : 'Lägg till organisation'}
      show={show}
      setShow={onModalHide}
      buttonInfo={{
        label: editOrgData ? 'Spara' : 'Lägg till organisation', 
        action: onSaveOrganisation,
        loaderShow: btnLoader,
      }}
    >
      <div className={`app-add-supplier app-create-project app-edit-supplier`}>
        <div className="page-paddings">
          <BtGrid>
            <BtRow>
              <BtColumn className="colum-left pp-col-left">
                <div className="content-left">{renderFormSlide()}</div>
              </BtColumn>
            </BtRow>
          </BtGrid>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(AddOrganization, areEqual);
